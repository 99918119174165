import * as React from "react";
import { RecruitContents, Container, Breadcrumb } from "../components";
import Seo from "../components/Seo";

const RecruitPage = () => {
  return (
    <Container>
      <Seo
        title="採用情報"
        description="TKCホールディングの採用情報ページです。募集要項や働きやすさを支える制度などをご覧いただけます。IT企業での実務経験を積みたい方や日本のIT産業を盛り上げたい方はぜひご応募ください。"
      />
      <RecruitContents />
      <Breadcrumb currentPage="Recruit" currentSlug="/recruit" />
    </Container>
  );
};

export default RecruitPage;
